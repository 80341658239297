import React, { Fragment, useEffect, useRef } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { useUser } from "../redux/slices/authSlice";
import App from "../components/app";
import BankTransaction from "../pages/Payin_Payout/BankTransaction/BankTransaction";
import Transaction from "../pages/Payin_Payout/Transaction/Transaction";
import Payout from "../pages/Payin_Payout/Payout/Payout";
import Merchant from "../pages/Payin_Payout/Merchant/Merchant";
import Report from "../pages/Support/Report/Report";
import Home from "../pages/Main/Home/Home";
import Support_log from "../pages/Support/Support_log/Support_log";
import Webhook_Event from "../pages/Support/Webhook_Event/Webhook_Event";
import Bank_Statement from "../pages/Support/Bank_Statement/Bank_Statement";
import BankSync from "../pages/Main/BankSync/BankSync";
import Payout_Manual from "../pages/Payin_Payout/Payout_Manual/Payout_Manual";
import MobileSync from "../pages/Main/MobileSync/MobileSync";
import MerchantPayIn from "../pages/Payin_Payout/Merchant/MerchantPayIn";
import MerchantPayOut from "../pages/Payin_Payout/Merchant/MerchantPayOut";
import MerchantDetails from "../pages/Payin_Payout/Merchant/MerchantDetails";
import Pg from "../pages/Pg/Pg";
import UtrReconcilation from "../pages/Payin_Payout/UtrReconcilation/UtrReconcilation";
import WhitelistClient from "../pages/Payin_Payout/WhitelistClient/WhitelistClient";
import Proxy from "../pages/Developer/Proxy/Proxy";
import BankProxy from "../pages/Developer/BankProxy/BankProxy";
import PgRouter from "../pages/Developer/PgRouter/PgRouter";
import SmsLogs from "../pages/Developer/SmsLogs/SmsLogs";
import MailReader from "../pages/Developer/MailReader/MailReader";
import IdfcMailWebhook from "../pages/Developer/IdfcMailWebhook/IdfcMailWebhook";
import WithdrawalBankDown from "../pages/Developer/WithdrawalBankDown/WithdrawalBankDown";
import Bouncer from "../pages/Developer/Bouncer/Bouncer";
import PgPayIn from "../pages/Developer/PgPayIn/PgPayIn";
import Users from "../pages/Support/Add_User/Users";
import Tracking from "../pages/Support/Tracking/Tracking";
import BankMonitoring from "../pages/Payin_Payout/BankMonitoring/BankMonitoring";
import BankTicketSummery from "../pages/Payin_Payout/BankTicketSummery/BankTicketSummery";
import UpiPendingTxn from "../pages/Support/UpiPendingTxn/UpiPendingTxn";
import FedTransaction from "../pages/Payin_Payout/FedTransaction/FedTransaction";
import UpiBankSuccess from "../pages/Support/UpiBankSuccess/UpiBankSuccess";
import Login from "../pages/Auth/Login/Login";
import TicketSummary from "../pages/Payin_Payout/TicketSummary/TicketSummary";
import Reseller from "../pages/Support/Reseller/Reseller";
import ResellerDetails from "../pages/Support/Reseller/ResellerDetails";
import ResellerSettlement from "../pages/Support/Reseller/ResellerSettlement";
import BankMeta from "../pages/Payin_Payout/BankMeta/BankMeta";
import RequestResponseLogs from "../pages/Support/RequestResponseLogs/RequestResponseLogs";
import BankLimit from "../pages/Payin_Payout/BankLimit/BankLimit";
import SsTransaction from "../pages/Payin_Payout/SsTransaction/SsTransaction";
import SsTransactionDetails from "../pages/Payin_Payout/SsTransaction/SsTransactionDetails";
import StateSummary from "../pages/Payin_Payout/StateSummary/StateSummary";
import BankParse from "../pages/Payin_Payout/BankParse/BankParse";
import Reconciliation from "../pages/Payin_Payout/Reconciliation/Reconciliation";
import ScreenshotSupport from "../pages/Payin_Payout/ScreenshotSupport/ScreenshotSupport";
import FedConfigBank from "../pages/Developer/FedConfigBank/FedConfigBank";
import CustomerManagement from "../pages/Support/CustomerManagement/CustomerManagement";
import PayoutReqResLogs from "../pages/Support/PayoutReqResLogs/PayoutReqResLogs";
import PayinReqResLogs from "../pages/Support/PayinReqResLogs/PayinReqResLogs";
import MerchantGoogleAnalytic from "../pages/Developer/merchantGoogleAnalytic/MerchantGoogleAnalytic";
import Metriwes from "../pages/Payin_Payout/Metriwes/Metriwes";
import ActiveMerchantPG from "../pages/Payin_Payout/ActiveMerchantPG/ActiveMerchantPG";
import RiskManagement from "../pages/Payin_Payout/RiskManagement/RiskManagement";
import ActiveUpiIds from "../pages/Payin_Payout/ActiveUpiIds/ActiveUpiIds";
import LastUpiRate from "../pages/Payin_Payout/LastUpiRate/LastUpiRate";
import PgConfig from "../pages/Developer/PgConfig/PgConfig";
import ReportAndChart from "../pages/Support/ReportAndChart/ReportAndChart";
import PgWebHook from "../pages/Support/PgWebHook/PgWebHook";
import Roles from "../pages/Support/Roles/Roles";
import AddRolesPage from "../pages/Support/Roles/AddRolesPage";
import BankBalSheet from "../pages/Payin_Payout/BankTransaction/BankBalSheet";
import WithdrawalCredit from "../pages/Support/WithdrawalCredit/WithdrawalCredit";
import AddPg from "../pages/Developer/AddPg/AddPg";
import BankAnalytics from "../pages/Payin_Payout/BankAnalytics/BankAnalytics";
import SmsSync from "../pages/Main/SmsSync/SmsSync";
import UserDetails from "../pages/Main/SmsSync/UserDetails";
import PayoutTicketSummary from "../pages/Payin_Payout/PayoutTicketSummary/PayoutTicketSummary";
import BankTurnOver from "../pages/Payin_Payout/BankTurnOver/BankTurnOver";
import { useDispatch } from "react-redux";
import WebhookStatus from "../pages/Support/WebhookStatus/WebhookStatus";
import { LowBal_Payout_To_Initialize_Ids_Reset, setStoredTimeouts } from "../redux/slices/payoutSlice";
import { responseToaster } from "../helperFunctions";
import VPAVerify from "../pages/Payin_Payout/VPAVerify/VPAVerify";

const AllRoutes = () => {
  const { user } = useUser();
  const timeoutsRef = useRef(new Set());
  const dispatch = useDispatch();

  const Components = {
    // MAIN
    "/dashboard": Home,
    "/bank-sync": BankSync,
    "/mobilrsync": MobileSync,
    "/getSms": SmsSync,

    // DEPOSIT WITHDRAWAL
    "/transaction": Transaction,
    "/payout": Payout,
    "/bank-transaction": BankTransaction,
    "/merchant": Merchant,
    "/utr": UtrReconcilation,
    "/manual-payout": Payout_Manual,
    "/withdrawalWhitelistClient": WhitelistClient,
    "/bank-monitoring": BankMonitoring,
    "/get/bank/Ticket/summery": BankTicketSummery,
    "/get/Ticket/summery": TicketSummary,
    "/fedTransaction": FedTransaction,
    "/ss-transaction": SsTransaction,
    "/get-screenshot": ScreenshotSupport,
    "/parsepg": Metriwes,
    "/activeMerchantPg": ActiveMerchantPG,
    "/risk-management": RiskManagement,
    "/active-upi-ids": ActiveUpiIds,
    "/last-upi-rate": LastUpiRate,
    "/get/payout/Ticket/summary": PayoutTicketSummary,

    // BANK
    "/getBankMeta": BankMeta,
    "/getPaymentBankLimit": BankLimit,
    "/bank-turnover": BankTurnOver,
    "/stateSummary": StateSummary,
    "/upload-excel": BankParse,
    "/bank-analytics": BankAnalytics,
    "/verify-vpa": VPAVerify,

    // SUPPORT
    "/support-logs": Support_log,
    "/webhook-events": Webhook_Event,
    "/report": Report,
    "/bank-statement": Bank_Statement,
    "/getSuperadminUser": Users,
    "/get-roles": Roles,
    "/Tracking": Tracking,
    "/getPendingpaymentData": UpiPendingTxn,
    "/get-transaction-data": UpiBankSuccess,
    "/resellerList": Reseller,
    "/RequestResponseLogs": RequestResponseLogs,
    "/customer-management": CustomerManagement,
    "/payout-req-res-logs": PayoutReqResLogs,
    "/payin-req-res-logs": PayinReqResLogs,
    "/reportAndChart/deposit": ReportAndChart,
    "/pg-webhooks": PgWebHook,
    "/get/PayoutCreditList": WithdrawalCredit,
    "/support/GetWebhookStatus": WebhookStatus,

    // DEVELOPER
    "/dashboard/GetPgRouters": PgRouter,
    "/dashboard/GetProxyList": Proxy,
    "/dashboard/GetBankProxyList": BankProxy,
    "/dashboard/getSMSLogs": SmsLogs,
    "/dashboard/GetMailReader": MailReader,
    "/dashboard/getidfcwebhook": IdfcMailWebhook,
    "/dashboard/GetPayoutDownBanks": WithdrawalBankDown,
    "/dashboard/GetBouncerData": Bouncer,
    "/dashboard/GetPgPayInSummary": PgPayIn,
    "/fedConfigBank": FedConfigBank,
    "/dashboard/GoogleCode": MerchantGoogleAnalytic,
    "/reconciliation": Reconciliation,
    "/pg-config": PgConfig,
    "/payment-gateway/AddPaymentMeta": AddPg,
  };
  useEffect(() => {
    if (user && user?.token) {
      const storedTimeouts = JSON.parse(localStorage.getItem("resetBlc")) || {};

      Object.entries(storedTimeouts).forEach(([id, expiry]) => {
        const remainingTime = expiry - Date.now();

        if (remainingTime > 0) {
          if (timeoutsRef.current.has(id)) {
            return;
          }

          setTimeout(async () => {
            try {
              const response = await dispatch(
                LowBal_Payout_To_Initialize_Ids_Reset()
              ).unwrap();
              responseToaster(response);
            } catch (err) {}
            delete storedTimeouts[id];
            localStorage.setItem(
              "resetBlc",
              JSON.stringify(storedTimeouts)
            );
            dispatch(setStoredTimeouts(storedTimeouts))
          }, remainingTime);

          timeoutsRef.current.add(id);
        } else {
          delete storedTimeouts[id];
          localStorage.setItem("resetBlc", JSON.stringify(storedTimeouts));
          dispatch(setStoredTimeouts(storedTimeouts))
        }
      });

      return () => {
        timeoutsRef.current.forEach((timeoutId) => {
          clearTimeout(timeoutId);
        });
      };
    }
  }, [user?.token]);

  return (
    <BrowserRouter>
      <Routes>
        {user && user?.token ? (
          <Route path={``} element={<App />}>
            {/*MAIN*/}
            <Route index element={<Navigate to={user?.firstRoute} />} />
            {user?.SiderBar?.MAIN?.map((item, i) => {
              const Component = Components?.[item?.route];
              if (!Component) {
                return <Fragment key={i}></Fragment>;
              }
              return (
                <Fragment key={i}>
                  <Route path={item?.route} element={<Component />} />
                </Fragment>
              );
            })}

            {/* PAYIN & PAYOUT  */}
            {user?.SiderBar?.["DEPOSIT WITHDRAWAL"]?.map((item, i) => {
              const Component = Components?.[item?.route];
              if (!Component) {
                return <Fragment key={i}></Fragment>;
              }
              return (
                <Fragment key={i}>
                  <Route path={item?.route} element={<Component />} />
                </Fragment>
              );
            })}
            {user?.SiderBar?.["MAIN"]?.find(
              (item) => item?.route === "/merchant"
            ) ? (
              <>
                <Route path="/merchant/:id" element={<MerchantDetails />} />
                <Route
                  path="/merchant/:id/withdrawal"
                  element={<MerchantDetails />}
                />
                <Route
                  path="/merchant/pay-in/:id"
                  element={<MerchantPayIn />}
                />
                <Route
                  path="/merchant/pay-out/:id"
                  element={<MerchantPayOut />}
                />
                <Route
                  path="/ss-transaction/:id"
                  element={<SsTransactionDetails />}
                />
              </>
            ) : null}
            {user?.SiderBar?.["MAIN"]?.find(
              (item) => item?.route === "/getSms"
            ) ? (
              <>
                <Route path="/getSms/user/:id" element={<UserDetails />} />
              </>
            ) : null}
            {user?.SiderBar?.["SUPPORT"]?.find(
              (item) => item?.route === "/resellerList"
            ) ? (
              <>
                <Route path="/resellerList/:id" element={<ResellerDetails />} />
                <Route
                  path="/resellerList/settlement"
                  element={<ResellerSettlement />}
                />
              </>
            ) : null}

            {user?.SiderBar?.["SUPPORT"]?.find(
              (item) => item?.route === "/get-roles"
            ) ? (
              <>
                <Route path="/updateRole/:id" element={<AddRolesPage />} />
                <Route
                  path="/addRole"
                  element={<AddRolesPage />}
                />
              </>
            ) : null}

            {user?.SiderBar?.["SUPPORT"]?.find(
              (item) => item?.route === "/payin-req-res-logs"
            ) ? (
              <>
                <Route
                  path="/payin-req-res-logs/:id"
                  element={<PayinReqResLogs />}
                />
              </>) : null}

            {user?.SiderBar?.["SUPPORT"]?.find(
              (item) => item?.route === "/payout-req-res-logs"
            ) ? (
              <>
                <Route
                  path="/payout-req-res-logs/:id"
                  element={<PayoutReqResLogs />}
                />
              </>) : null}

            {/* SUPPORT */}
            {user?.SiderBar?.SUPPORT?.map((item, i) => {
              const Component = Components?.[item?.route];
              if (!Component) {
                return <Fragment key={i}></Fragment>;
              }
              return (
                <Fragment key={i}>
                  <Route path={item?.route} element={<Component />} />
                </Fragment>
              );
            })}

            {/* PG */}
            {user?.SiderBar?.PG?.map((item, i) => {
              return (
                <Fragment key={i}>
                  {item?.child?.map((subItem, i) => {
                    return (
                      <Fragment key={i}>
                        <Route
                          path={subItem?.route}
                          element={<Pg key={subItem?.route} />}
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}

            {/* DEVELOPER */}
            {user?.SiderBar?.DEVELOPER?.map((item, i) => {
              const Component = Components?.[item?.route];
              if (!Component) {
                return <Fragment key={i}></Fragment>;
              }
              return (
                <Fragment key={i}>
                  <Route path={item?.route} element={<Component />} />
                </Fragment>
              );
            })}

            {/* BANK */}
            {user?.SiderBar?.BANK?.map((item, i) => {
              const Component = Components?.[item?.route];
              if (!Component) {
                return <Fragment key={i}></Fragment>;
              }
              return (
                <Fragment key={i}>
                  <Route path={item?.route} element={<Component />} />
                </Fragment>
              );
            })}
            {user?.SiderBar?.BANK?.find(
              (item) => item?.route === "/bank-transaction"
            ) ? (
              <>
                <Route path="/bank-transaction/bal/sheet/:bankId" element={<BankBalSheet />} />
              </>
            ) : null}
            <Route path="*" element={<Navigate to={user?.firstRoute} />} />
            <Route path="/screenshot" element={<ScreenshotSupport />} />
          </Route>
        ) : (
          <Route>
            <Route index element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default AllRoutes;
