import { Button, Card, CardBody, CardHeader, Form, Modal } from "reactstrap";
import { capitalCase, capitalCaseLabel, responseToaster } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { pgAdd, pgUpdateBank } from "../../redux/slices/pgSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDropDown from "../../components/Custom/Forms/Controller/ControlledDropDown";
import { NUMBER_TYPE_FIELDS, showToaster, UPPERCASE_LABEL } from "../../constant";
import { getBanklistOptions } from "./mock";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getPgIdFromPgName } from "./Pg";
import { useUser } from "../../redux/slices/authSlice";
import ConfirmationModal from "./ConfirmationModal";

const nameChanges = {
  payout: "withdrawal",
  payin: "deposit",
};

const PgUpdateBankModal = (props) => {
  const { onHide, config, isOpen, onGet, params } = props;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCon, setIsOpenCon] = useState(false)
  const { user } = useUser();

  const schema = Yup.object({
    // pg_type: Yup.string().trim().required("Please Select PG Type"),
    // pg_name: Yup.string().trim().required("Please Add PG Name"),
    // label: Yup.string().trim().required("Please Add label"),
    // account_id: Yup.string().trim().required("Please Add Account ID"),
    // email_id: Yup.string().trim().required("Please Add Email ID"),
    // bank_name: Yup.string().trim().nullable().required("Please Add Bank Name"),
    // bank_type: Yup.string().trim().required("Please Add Bank Type"),
    // account_number: Yup.string().trim().required("Please Add Account Number"),
    // ifsc_code: Yup.string().trim().required("Please Add IFSC Code"),
    // upi_id: Yup.string().trim().required("Please Add UPI Id"),
    // min_limit: Yup.string().trim().required("Please Add Min Limit"),
    // max_limit: Yup.string().trim().required("Please Add Max Limit"),
    // daily_limit: Yup.string().trim().required("Please Add Daily Limit"),
    merchant_id: Yup.string().trim().nullable().required("Please Add Merchant Id"),
    ...(params?.pgType === "payout" && { label: Yup.string().trim().nullable().required("Please Add Label") }),
  });

  const initialValues = {
    pg_type: params?.pgType,
    pg_name: params?.pgName,
    // "account_id": "",
    "client_id": "",
    "label": "",
    "email_id": "",
    "username": "",
    "password": "",
    "api_key": "",
    "api_token": "",
    "secret_key": "",
    "salt": "",
    "udf1": "", "udf2": "", "udf3": "", "udf4": "", "udf5": "",
    "bank_name": "",
    "bank_type": "",
    "account_number": "",
    "ifsc_code": "",
    "upi_id": "",
    "mobile_number": "",
    "bouncer_sub_domain_url": "",
    "callback_sub_domain_url": "",
    "webhook_url": "",
    "min_limit": "",
    "max_limit": "",
    "daily_limit": "",
    "proxy_id": "",
    "merchant_id": "",
    account_holder_name: ""
  };

  const onSubmit = () => {
    const values = getValues();
    if (params?.pgType === "payin" && (!values?.label && !values?.account_holder_name)) {
      showToaster("Add either a label or the account holder's name", "Warning")
      return
    }
    if ((values?.api_key || values?.api_token || values?.secret_key || values?.salt || values?.udf1 || values?.udf2 || values?.udf3 || values?.udf4 || values?.udf5) && isOpen?.account_id) {
      setIsOpenCon(true)
      return
    }
    handleUpdatePg(values)
  };

  const handleUpdatePg = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        formData: { ...values, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
      };
      const res = await dispatch(
        isOpen?.account_id ? pgUpdateBank(payload) : pgAdd(payload)
      ).unwrap();
      responseToaster(res);
      if (!!res?.status) {
        onGet();
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  const { control, getValues, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const getOptions = (column) => {
    let options = [];
    if (column === "bank_name") {
      options = getBanklistOptions?.sort((a, b) => a?.label.localeCompare(b?.label))?.map((val) => ({
        value: val?.label,
        label: val?.label,
      }));
    } else if (config?.proxyList?.length) {
      if (column === "proxy_id") {
        options = [
          ...config?.proxyList?.map((item) => ({
            value: item?.id,
            label: item?.label_name,
          })),
        ];
      } else if (column === "is_seamless") {
        options = [
          {
            value: "1",
            label: "Yes",
          },
          {
            value: "0",
            label: "No",
          },
        ];
      } else if (column === "pg_type") {
        options = [
          {
            value: "Payout",
            label: "Payout",
          },
          {
            value: "Payin",
            label: "Payin",
          },
        ]
      }
      else if (column === "type") {
        options = [
          {
            value: "auto",
            label: "Auto",
          },
          {
            value: "manual",
            label: "Manual",
          },
        ]
      }
      else if (column === "bank_type") {
        options = [
          { value: "current", label: "current" },
          { value: "saving", label: "saving" },
        ]
      }
    }
    return [...options];
  };

  useEffect(() => {
    if (isOpen && isOpen?.account_id) {
      initialValue()
    }

  }, [isOpen])

  const initialValue = () => {
    setValue("pg_type", isOpen?.pg_type || params?.pgType)
    setValue("pg_name", isOpen?.pg_name || params?.pgName)
    setValue("account_id", isOpen?.account_id)
    setValue("client_id", isOpen?.client_id)
    setValue("label", isOpen?.label)
    setValue("email_id", isOpen?.email_id)
    setValue("username", isOpen?.username)
    // setValue("label", isOpen?.label)
    setValue("password", isOpen?.password)
    setValue("api_key", isOpen?.api_key)
    setValue("api_token", isOpen?.api_token)
    setValue("secret_key", isOpen?.secret_key)
    setValue("salt", isOpen?.salt)
    setValue("udf1", isOpen?.udf1)
    setValue("udf2", isOpen?.udf2)
    setValue("udf3", isOpen?.udf3)
    setValue("udf4", isOpen?.udf4)
    setValue("udf5", isOpen?.udf5)
    setValue("bank_name", isOpen?.bank_name)
    setValue("bank_type", isOpen?.bank_type)
    setValue("account_number", isOpen?.account_number)
    setValue("ifsc_code", isOpen?.ifsc_code)
    setValue("upi_id", isOpen?.upi_id)
    setValue("mobile_number", isOpen?.mobile_number)
    setValue("bouncer_sub_domain_url", isOpen?.bouncer_sub_domain_url)
    setValue("callback_sub_domain_url", isOpen?.callback_sub_domain_url)
    setValue("webhook_url", isOpen?.webhook_url)
    setValue("min_limit", isOpen?.min_limit)
    setValue("max_limit", isOpen?.max_limit)
    setValue("daily_limit", isOpen?.daily_limit)
    setValue("proxy_id", +isOpen?.proxy_id)
    setValue("merchant_id", isOpen?.merchant_id)
    setValue("account_holder_name", isOpen?.account_holder_name)
    if (params?.pgType === "payout") {
      setValue("remain_limit", isOpen?.remain_limit)
    }
  }

  if (!isOpen) {
    return
  }

  const getName = () =>
    `${capitalCase(params?.pgName)} ${capitalCase(
      nameChanges?.[params?.pgType?.toLowerCase()]
    )} Bank`;

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4 className="main-title">
                <b>
                  {
                    `${!isOpen?.account_id ? "Add" : "Update"} ${getName()}`
                  }
                </b>
              </h4>
              <div className="w-100 d-flex justify-content-end button-space">
                <Button className="graybutton" onClick={() => onClose()}>
                  Cancel
                </Button>
                <CustomeButton
                  type="submit"
                  className="submitbutton"
                  isButtonLoading={isLoading}
                >
                  {`${!isOpen?.account_id ? "Add" : "Update"}`}
                </CustomeButton>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {/*  <div className='py-1 border-bottom'>
            <div className="d-flex align-items-start button-space flex-wrap">
              {[
                "type",

              ]?.map((item, index) => {
                if (
                  item === "proxy_id" ||
                  item === "is_seamless" ||
                  item === "bank_name" ||
                  item === "pg_type" ||
                  item === "type" ||
                  item === "bank_type"
                ) {
                  return (
                    <div className="select-div" key={index}>
                      <ControlledDropDown
                        name={item}
                        label={capitalCaseLabel(item)}
                        options={getOptions(item)}
                        placeholder={`Select ${capitalCaseLabel(item)}`}
                        control={control}
                        errors={errors}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: "width",
                            minHeight: "30px",
                            borderRadius: "6px",
                            borderColor: "#c9d5e1",
                            fontSize: 14,
                            height: "40px",
                          }),
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div className="select-div" key={index}>
                    <ControlledInput
                      name={item}
                      label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                      placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                      type={["min_limit", "max_limit", "daily_limit", NUMBER_TYPE_FIELDS].includes(item) ? "number" : ["api_key",
                        "api_token",
                        "secret_key",
                        "salt"]?.includes(item) && isOpen?.account_id ? "password" : "text"}
                      autoComplete="new-password"
                      disabled={isOpen?.account_id && ["account_id"]?.includes(item)}
                      control={control}
                      errors={errors}
                      inputClassName="addpg_input"
                    />
                  </div>
                );
              })}
            </div>
          </div> */}
            <div className="py-1 border-bottom">
              <div className="d-flex align-items-start button-space flex-wrap">
                {["bank_name",
                  "bank_type",
                  "account_number",
                  "ifsc_code",
                  "upi_id"
                ]?.map((item, index) => {
                  if (
                    item === "proxy_id" ||
                    item === "is_seamless" ||
                    item === "bank_name" ||
                    item === "pg_type" ||
                    item === "type" ||
                    item === "bank_type"
                  ) {
                    return (
                      <div className="select-div" key={index}>
                        <ControlledDropDown
                          name={item}
                          label={capitalCaseLabel(item)}
                          options={getOptions(item)}
                          placeholder={`Select ${capitalCaseLabel(item)}`}
                          control={control}
                          errors={errors}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "width",
                              minHeight: "30px",
                              borderRadius: "6px",
                              borderColor: "#c9d5e1",
                              fontSize: 14,
                              height: "40px",
                            }),
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="select-div" key={index}>
                      <ControlledInput
                        name={item}
                        label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                        placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                        type={["min_limit", "max_limit", "daily_limit", "account_number", NUMBER_TYPE_FIELDS].includes(item) ? "number" : ["api_key",
                          "api_token",
                          "secret_key",
                          "salt"]?.includes(item) && isOpen?.account_id ? "password" : "text"}
                        autoComplete="new-password"
                        disabled={isOpen?.account_id && ["account_id"]?.includes(item)}
                        control={control}
                        errors={errors}
                        inputClassName="addpg_input"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='py-1 border-bottom'>
              <div className="d-flex align-items-start button-space flex-wrap">
                {[
                  "email_id",
                  "username",
                  "password",
                  "mobile_number",
                ]?.map((item, index) => {
                  if (
                    item === "proxy_id" ||
                    item === "is_seamless" ||
                    item === "bank_name" ||
                    item === "pg_type" ||
                    item === "type" ||
                    item === "bank_type"
                  ) {
                    return (
                      <div className="select-div" key={index}>
                        <ControlledDropDown
                          name={item}
                          label={capitalCaseLabel(item)}
                          options={getOptions(item)}
                          placeholder={`Select ${capitalCaseLabel(item)}`}
                          control={control}
                          errors={errors}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "width",
                              minHeight: "30px",
                              borderRadius: "6px",
                              borderColor: "#c9d5e1",
                              fontSize: 14,
                              height: "40px",
                            }),
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="select-div" key={index}>
                      <ControlledInput
                        name={item}
                        label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                        placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                        type={["min_limit", "max_limit", "daily_limit", "mobile_number", NUMBER_TYPE_FIELDS].includes(item) ? "number" : ["api_key",
                          "api_token",
                          "secret_key",
                          "salt"]?.includes(item) && isOpen?.account_id ? "password" : "text"}
                        autoComplete="off"
                        disabled={isOpen?.account_id && ["account_id"]?.includes(item)}
                        control={control}
                        onChange={(e) => {
                          if (
                            item === "mobile_number" &&
                            e?.target?.value?.length <= 10 &&
                            (e?.target?.value === "" ||
                              /^\d+$/.test(e?.target?.value))
                          ) {
                            setValue("mobile_number", e?.target?.value);
                          } else {
                            if (item !== "mobile_number") {
                              setValue(item, e?.target?.value);
                            }
                          }
                        }}
                        errors={errors}
                        inputClassName="addpg_input"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='py-1'>
              <div className="d-flex align-items-start button-space flex-wrap">
                {[
                  // "account_id",
                  "client_id",
                  "proxy_id",
                  "api_key",
                  "api_token",
                  "secret_key",
                  "salt",
                  "udf1", "udf2", "udf3", "udf4", "udf5",
                  "bouncer_sub_domain_url",
                  "callback_sub_domain_url",
                  "webhook_url",
                  "min_limit",
                  "max_limit",
                  "daily_limit",
                  ...(params?.pgType === "payout" ? ["remain_limit"] : []),
                  "label",
                  "merchant_id",
                  ...(params?.pgType === "payin" ? ["account_holder_name"] : []),
                ]?.map((item, index) => {
                  if (
                    item === "proxy_id" ||
                    item === "is_seamless" ||
                    item === "bank_name" ||
                    item === "pg_type" ||
                    item === "type" ||
                    item === "bank_type"
                  ) {
                    return (
                      <div className="select-div" key={index}>
                        <ControlledDropDown
                          name={item}
                          label={capitalCaseLabel(item)}
                          options={getOptions(item)}
                          placeholder={`Select ${capitalCaseLabel(item)}`}
                          control={control}
                          errors={errors}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "width",
                              minHeight: "30px",
                              borderRadius: "6px",
                              borderColor: "#c9d5e1",
                              fontSize: 14,
                              height: "40px",
                            }),
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="select-div" key={index}>
                      <ControlledInput
                        name={item}
                        label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                        placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                        type={["min_limit", "max_limit", "daily_limit", "remain_limit", NUMBER_TYPE_FIELDS].includes(item) ? "number" : ["api_key",
                          "api_token",
                          "secret_key",
                          "salt", "udf1", "udf2", "udf3", "udf4", "udf5"]?.includes(item) && isOpen?.account_id ? "password" : "text"}
                        autoComplete="new-password"
                        disabled={isOpen?.account_id && ["account_id"]?.includes(item)}
                        control={control}
                        errors={errors}
                        inputClassName="addpg_input"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </CardBody>
        </Card>
      </Form>
      <ConfirmationModal isOpen={isOpenCon} onClose={() => {
        setIsOpenCon(false)
        // initialValue()
        // onClose()
      }}
        isLoading={isLoading}
        onUpdate={() => {
          const values = getValues();
          handleUpdatePg(values)
        }}
      />
    </>
  );
};

export default PgUpdateBankModal;
