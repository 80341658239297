// ** Redux Imports
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import payoutSlice from "./slices/payoutSlice";
import transactionSlice from "./slices/transactionSlice";
import payinPayoutSlice from "./slices/payinPayoutSlice";
import merchantSlice from "./slices/merchantSlice";
import bankTransactionSlice from "./slices/bankTransactionSlice";
import webhookSlice from "./slices/webhookSlice";
import dashboardSlice from "./slices/dashboardSlice";
import paymentMethodSlice from "./slices/paymentMethodSlice";
import customerSlice from "./slices/customerSlice";
import bankStatementSlice from "./slices/bankStatementSlice";
import ReportSlice from "./slices/ReportSlice";
import supportSlice from "./slices/supportSlice";
import utrReconciliationSlice from "./slices/utrReconciliationSlice";
import whitelistClientSlice from "./slices/whitelistClientSlice";
import dashboardDeveloperSlice from "./slices/dashboardDeveloperSlice";
import pgRouterSlice from "./slices/pgRouterSlice";
import proxySlice from "./slices/proxySlice";
import bouncerSlice from "./slices/bouncerSlice";
import withdrawalBankDownSlice from "./slices/withdrawalBankDownSlice";
import withdrawalClientsSlice from "./slices/withdrawalClientsSlice";
import DepositSummarySlice from "./slices/DepositSummarySlice";
import smsLogsSlice from "./slices/smsLogsSlice";
import mailReaderSlice from "./slices/mailReaderSlice";
import bankProxySlice from "./slices/bankProxySlice";
import swiftCustomerSlice from "./slices/swiftCustomerSlice";
import idfcMailWebhookSlice from "./slices/idfcMailWebhookSlice";
import usersSlice from "./slices/usersSlice";
import pgPayInSlice from "./slices/pgPayInSlice";
import trackingSlice from "./slices/trackingSlice";
import bankMonitoringSlice from "./slices/bankMonitoringSlice";
import upiBankSuccessSlice from "./slices/upiBankSuccessSlice";
import resellerSlice from "./slices/resellerSlice";
import bankMetaSlice from "./slices/bankMetaSlice";
import requestResponseLogsSlice from "./slices/requestResponseLogsSlice";
import ssTransactionSlice from "./slices/ssTransactionSlice";
import stateSummarySlice from "./slices/stateSummarySlice";
import bankParseSlice from "./slices/bankParseSlice";
import reconciliationSlice from "./slices/reconciliationSlice";
import screenshotSupportSlice from "./slices/screenshotSupportSlice";
import fedConfigBankSlice from "./slices/fedConfigBankSlice";
import customerManagementSlice from "./slices/customerManagementSlice";
import payoutReqResLogsSlice from "./slices/payoutReqResLogsSlice";
import payinReqResLogsSlice from "./slices/payinReqResLogsSlice";
import merchantGoogleAnalyticSlice from "./slices/merchantGoogleAnalyticSlice";
import metriwsSlice from "./slices/metriwsSlice";
import riskManagementSlice from "./slices/riskManagementSlice";
import activeUpiIdsSlice from "./slices/activeUpiIdsSlice";
import lastUpiRateSlice from "./slices/lastUpiRateSlice";
import pgConfigSlice from "./slices/pgConfigSlice";
import reportAndChartSlice from "./slices/reportAndChartSlice";
import pgWebHookSlice from "./slices/pgWebHookSlice";
import rolesSlice from "./slices/rolesSlice";
import bankBalSheetSlice from "./slices/bankBalSheetSlice";
import withdrawalCreditSlice from "./slices/withdrawalCreditSlice";
import addPgSlice from "./slices/addPgSlice";
import BankAnalyticsSlice from "./slices/BankAnalyticsSlice";
import smsSlice from "./slices/smsSlice";
import webhookStatusSlice from "./slices/webhookStatusSlice";
import vpaVerifySlice from "./slices/vpaVerifySlice";

const combineReducer = combineReducers({
  auth: authSlice,
  payout: payoutSlice,
  transaction: transactionSlice,
  ssTransaction: ssTransactionSlice,
  payinPayout: payinPayoutSlice,
  merchant: merchantSlice,
  bankTransaction: bankTransactionSlice,
  report: ReportSlice,
  support: supportSlice,
  payoutReqResLogs: payoutReqResLogsSlice,
  payinReqResLogs: payinReqResLogsSlice,
  webhook: webhookSlice,
  dashboard: dashboardSlice,
  paymentMethod: paymentMethodSlice,
  customer: customerSlice,
  statement: bankStatementSlice,
  utrReconciliation: utrReconciliationSlice,
  whitelistClient: whitelistClientSlice,
  dashboardDeveloper: dashboardDeveloperSlice,
  pgRouter: pgRouterSlice,
  proxy: proxySlice,
  bouncer: bouncerSlice,
  withdrawalBankDown: withdrawalBankDownSlice,
  withdrawalClients: withdrawalClientsSlice,
  depositSummary: DepositSummarySlice,
  smsLogs: smsLogsSlice,
  mailReader: mailReaderSlice,
  bankProxy: bankProxySlice,
  swiftCustomer: swiftCustomerSlice,
  idfcMailWebhook: idfcMailWebhookSlice,
  users: usersSlice,
  pgPayin: pgPayInSlice,
  tracking: trackingSlice,
  bankMonitoring: bankMonitoringSlice,
  upiBankSuccess: upiBankSuccessSlice,
  reseller: resellerSlice,
  bankMeta: bankMetaSlice,
  requestResponseLogs: requestResponseLogsSlice,
  stateSummary: stateSummarySlice,
  bankParse: bankParseSlice,
  reconciliation: reconciliationSlice,
  screenshot: screenshotSupportSlice,
  fedConfigBank: fedConfigBankSlice,
  customerManagement: customerManagementSlice,
  merchantGoogleAnalytic: merchantGoogleAnalyticSlice,
  metriws: metriwsSlice,
  riskManagement: riskManagementSlice,
  activeUpiIds: activeUpiIdsSlice,
  lastUpiRate: lastUpiRateSlice,
  pgConfig: pgConfigSlice,
  reportAndChart: reportAndChartSlice,
  pgWebHook: pgWebHookSlice,
  webHookStatus: webhookStatusSlice,
  roless: rolesSlice,
  bankBalSheet: bankBalSheetSlice,
  withdrawalCredit: withdrawalCreditSlice,
  addPg: addPgSlice,
  bankAnalytics: BankAnalyticsSlice,
  sms: smsSlice,
  vpaVerifys: vpaVerifySlice
});

const store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
});

export { store };
